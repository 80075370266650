/* eslint-disable simple-import-sort/imports */
import React, { useEffect, FC, ReactElement, Suspense, lazy } from "react"
import { useEzState, useEzActions } from "core/store"
import { Route, Routes, Navigate, useNavigate } from "react-router-dom"

import { PrivateRoute } from "./PrivateRoute.com"
import { DialogPool } from "./DialogPool.com"

import { Backdrop, CircularProgress } from "@mui/material"
import { store } from "core/store/store"
import { cloneDeep } from "lodash"
import { Playground } from "other/playground/Playground.com"
import { GocarlessCompleteRedirectFlowPopup } from "./GocarlessCompleteRedirectFlowPopup"
import { NouveauDevisSD } from "pages/standalone/nouveau-devis-sd/NouveauDevisSD"
import { NouvelleFactureSD } from "pages/standalone/nouvelle-facture-sd/NouvelleFactureSD"

const LoginPage = lazy(async () => {
    const componentModule = await import("pages/login-page/LoginPage.com" /* webpackChunkName: "login-page" */)
    return componentModule
})

const MainHub = lazy(async () => {
    const module = await import("pages/main-hub" /* webpackChunkName: "main-hub" */)

    store.addModel("mainHub", module.mainHubModel.obj)
    store.getActions().setStateFn((s) => {
        s.initialState.mainHub = cloneDeep(s.mainHub)
    })

    return module
})

const TeacherHub = lazy(async () => {
    const module = await import("pages/teacher-hub" /* webpackChunkName: "teacher-hub" */)

    store.addModel("teacherHub", module.teacherHubModel.obj)
    store.getActions().setStateFn((s) => {
        s.initialState.teacherHub = cloneDeep(s.teacherHub)
    })
    return module
})

const FamilyHub = lazy(async () => {
    const module = await import("pages/family-hub" /* webpackChunkName: "family-hub" */)

    store.addModel("familyHub", module.familyHubModel.obj)
    store.getActions().setStateFn((s) => {
        s.initialState.familyHub = cloneDeep(s.familyHub)
    })
    return module
})

export const App: FC = function (): ReactElement {
    //export const App: FC = ():ReactElement =>{
    console.log("App re-rendered")

    const isLoading = useEzState((s) => s.core.app.isLoading)
    const stopSpinner = useEzActions((s) => s.core.app.stopSpinner)
    const history = useNavigate()

    useEffect(() => {
        stopSpinner()
    }, [history]) //stop spinner when page changes
    //TODO: :teacherId(\d+) v6
    return (
        <React.Fragment>
            <Suspense fallback={<div>Chargement suspense...</div>}>
                <Routes>
                    <Route path="/*" /*element={<Navigate to="home" />}*/>
                        <Route path="" element={<Navigate replace to="home" />} />
                        <Route path="playground/*" element={<Playground />} />
                        <Route path="login/*" element={<LoginPage />} />
                        <Route path="home/*" element={<PrivateRoute component={MainHub} />} />
                        <Route path="profs/:teacherId/*" element={<PrivateRoute component={TeacherHub} />} />
                        <Route path="prospects/:prospectId/*" element={<PrivateRoute component={FamilyHub} />} />
                        <Route path="familles/:prospectId/*" element={<PrivateRoute component={FamilyHub} />} />
                    </Route>

                    <Route path="/oauth" element={<div />} />
                    <Route path="/gocardless-complete-redirect-flow-popup/families/:familyId" element={<GocarlessCompleteRedirectFlowPopup />} />
                    <Route path="/nouveau-devis-sd" Component={NouveauDevisSD} />
                    <Route path="/nouvelle-facture-sd" Component={NouvelleFactureSD} />
                    <Route path="*" element={<div>404 : not found (react router)</div>} />
                </Routes>
            </Suspense>

            <DialogPool />
            {isLoading && <LoadSpinner />}
        </React.Fragment>
    )
}

function LoadSpinner() {
    return (
        <Backdrop open style={{ zIndex: 4000, color: "#fff", backgroundColor: "rgba(0,0,0,0.5)" }}>
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}

// const MainHub = lazy(async () => {

//   const componentModule = await import('pages/main-hub/MainHub.com'/* webpackChunkName: "main-hub" */)
//   const modelModule = await import('pages/main-hub/mainHub.model')

//   store.addModel('mainHub', modelModule.mainHubModel.obj)

//   return componentModule
// })

// const TeacherHub = lazy(async () => {

//   const componentModule = await import('pages/teacher-hub/TeacherHub.com'/* webpackChunkName: "teacher-hub" */)
//   const modelModule = await import('pages/teacher-hub/teacherHub.model')

//   store.addModel('teacherHub', modelModule.teacherHubModel.obj)

//   return componentModule
// })

// const FamilyHub = lazy(async () => {

//   const componentModule = await import('pages/family-hub/FamilyHub.com'/* webpackChunkName: "family-hub" */)
//   const modelModule = await import('pages/family-hub/familyHub.model')

//   store.addModel('familyHub', modelModule.familyHubModel.obj)

//   return componentModule
// })
