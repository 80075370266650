import { FormControlLabel, Grid, Switch, Typography } from "@mui/material"
import { Bx, GoogleSearchLocationInput } from "core/misc"
import type { Coordinates } from "core/services/rasmik"
import { useEzActions,useEzState, useMapper } from "core/store"
import { GoogleMapParser } from "core/utils"
import { useField, useFormikContext } from "formik"
import type React from "react"
import { CSSProperties, ReactNode, useCallback, useEffect, useMemo, useState } from "react"
import { GoogleMap as ReactGoogleMap, GoogleMapProps, InfoWindow, InfoWindowProps,Marker, withGoogleMap, WithGoogleMapProps } from "react-google-maps"

import { RawTextField } from "../rawFields/RawTextField.com"

const defaultPlace: Coordinates = { lat: 49.126798901612965, lng: 6.184347455417095 }

//& {onMarkerDragEnd: (event:any)=>any, position: Coordinates}
function SubComponent(props: WithGoogleMapProps & { draggable?: boolean; defaultZoom?: number; lat: number; lng: number; onPositionChange: (position: Coordinates) => any; infoWindowText?: string }) {
    const { containerElement, mapElement, onPositionChange, lat, lng, draggable, defaultZoom, infoWindowText } = props

    // const [mapPosition, setMapPosition] = useState(defaultPlace)
    console.log("SubComponent rerendered", lat, lng)

    if (lat === undefined || lng === undefined || lat === null || lng === null) return null
    return (
        <ReactGoogleMap key="address-picker-react-google-map" defaultZoom={defaultZoom} defaultCenter={{ lat: lat, lng: lng }}>
            {lat && lng && (
                <>
                    {infoWindowText && (
                        <InfoWindow position={{ lat: lat + 0.0018, lng: lng }}>
                            <div>
                                <span style={{ padding: 0, margin: 0 }}>{infoWindowText}</span>
                            </div>
                        </InfoWindow>
                    )}

                    <Marker
                        draggable={draggable}
                        onDragEnd={(event) => {
                            const newCoordinates = { lat: event.latLng.lat(), lng: event.latLng.lng() }
                            onPositionChange(newCoordinates)
                        }}
                        position={{ lat: lat, lng: lng }}
                    />
                </>
            )}
        </ReactGoogleMap>
    )
}

const CustomMap = withGoogleMap(SubComponent) as any as React.FunctionComponent<React.ComponentProps<typeof SubComponent>>

// interface AddressPickerResult {
//     street: string
//     postalCode: string
//     city: string
//     position: Coordinates
//     manual: boolean
//     geocoderResult: google.maps.GeocoderResult
// }

// interface AddressPickerProps {
//     onChange: (result: AddressPickerResult) => any
//     error?: boolean
//     helperText?: string
//     value: {
//         street: string
//         postalCode: string
//         city: string
//         position: Coordinates
//         manual: boolean
//     }
// }

// /**
//  * Address form with autocomplete and map and manual fields
//  */
// export function AddressPicker(props: AddressPickerProps) {

//     const { value, onChange, error, helperText } = props
//     const [state, setState] = [null, null]
//     // const [state, setState] = useState<AddressPickerResult>({ ...value, geocoderResult: null })
//     // useEffect(() => {
//     //     value === undefined && setState({ ...state, ...value })
//     // }, [value.cityName, value.manual, value.position, value.postalCode, value.fullStreet])

//     const stalue = {geocoderResult: null, ...(value || state)}

//     function toggleManual() {
//         const newStalue = { ...stalue, manual: !stalue.manual }
//         // value === undefined && setState(newStalue)
//         onChange && onChange(newStalue)
//     }

//     function handleSearchResult(searchLocationResult: google.maps.GeocoderResult) {
//         const location = searchLocationResult.geometry.location
//         const coordinates = { lat: location.lat(), lng: location.lng() }

//         const parser = new GoogleMapParser(searchLocationResult)

//         const newStalue = {
//             ...stalue,
//             position: coordinates,
//             fullStreet: parser.getStreet(),
//             postalCode: parser.getPostalCode(),
//             cityName: parser.getCity(),
//             geocoderResult: searchLocationResult
//         }

//         value === undefined && setState(newStalue)
//         onChange && onChange(newStalue)
//     }

//     const handleMarkerMove =function handleMarkerMove(newPosition: Coordinates) {
//         const newStalue = { ...stalue, position: newPosition }
//         value === undefined && setState(newStalue)
//         onChange && onChange(newStalue)
//     }

//     function handleTextFieldChange(newValue: string, fieldName: string) {
//         const newStalue = { ...stalue, [fieldName]: newValue }
//         value === undefined && setState(newStalue)
//         onChange && onChange(newStalue)
//     }

//     return (
//         <>

//             <Grid item xs={12}>

//                 <Bx display='flex'>
//                     <Bx paddingTop="20px" paddingBottom="-10px" flexGrow={1}>
//                         <SearchLocationInput onSelect={searchLocationQuery => handleSearchResult(searchLocationQuery)}  />
//                             <Typography variant="caption">* Pour '23 bis' taper '23B', sans espace, puis vérifier l'adresse sous la carte, sinon, passer en manuel.</Typography>
//                     </Bx>

//                     <Bx display='flex' paddingLeft="17px" marginTop="-14px">
//                     <FormControlLabel control={<Switch checked={state.manual} onChange={toggleManual} size='small' />} label="Manuel" />
//                     </Bx>

//                 </Bx>
//             </Grid>

//             <Grid item paddingBottom="15px" width="100%" height="100%">
//                 {UseMemoMap}
//             </Grid>

//             <RawTextField key="street"  name="street" label="n° et rue" grid xs={6} fullWidth variant='outlined' size='small' onChange={(event) => handleTextFieldChange(event.target.value, event.target.name)} disabled={!state.manual} value={state.fullStreet} />

//             <RawTextField key="postalCode" name="postalCode" label="Code postal" grid xs={2} fullWidth variant='outlined' size='small' onChange={(event) => handleTextFieldChange(event.target.value, event.target.name)} disabled={!state.manual} value={state.postalCode} />

//             <RawTextField key="city" name="city" label="Ville" grid xs={4} fullWidth variant='outlined' size='small'  onChange={(event) => handleTextFieldChange(event.target.value, event.target.name)} disabled={!state.manual} value={state.cityName} />

//             {helperText && <Typography variant="caption" sx={{marginTop:'10px', ...(error && { color: theme => theme.palette.error.main }) }}>{helperText}</Typography>}

//         </>
//     )
// }

export function FmkAddressMap() {
    // const [coordinatesfield, coordinatesMeta, coordinatesHelpers] = useField('coordinates');
    const [latfield, latMeta, latHelpers] = useField("coordinates.lat")
    const [lngField, lngMeta, lngHelpers] = useField("coordinates.lng")
    const [isManualField, isManualMeta, isManualHelpers] = useField("isManual")

    const { values } = useFormikContext<any>()

    const onPositionChange = useCallback((coordinates) => {
        latHelpers.setValue(coordinates.lat)
        lngHelpers.setValue(coordinates.lng)
    }, [])

    return (
        <CustomMap
            key="address-picker-custom-map"
            lat={latfield.value}
            lng={lngField.value}
            onPositionChange={onPositionChange}
            draggable={isManualField.value}
            infoWindowText={`${values.number}${values.letter ? values.letter.toLowerCase() : ""} ${values.streetTypeLabel}${values.streetTypeLabel ? " " : ""}${values.streetName}, ${values.postalCode}, ${values.cityName}`}
            containerElement={<div id="map-container-element" style={{ height: "400px", width: "100%" }} />}
            mapElement={<div id="map-element" style={{ height: `100%`, width: "100%" }} />}
            defaultZoom={15}
        />
        // <div>Helllo</div>
    )
}
