import { Add as AddIcon, Bolt as BoltIcon,Close as CloseIcon, Delete as DeleteIcon, HourglassTop as HourglassTopIcon, Search as SearchIcon, Timeline as TimelineIcon } from "@mui/icons-material"
import DownloadIcon from "@mui/icons-material/Download"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import { Alert, AlertTitle, Box, Button, CircularProgress, Container, Divider, IconButton, InputAdornment, Stack, Table, type TableProps, Typography } from "@mui/material"
import { ButtonLink } from "core/atoms"
import { msgBox } from "core/dialogs/msgBox.model"
import { FmkDateFastField, FmkErrorMessage, FmkFastComboBox, FmkFastSwitch, FmkNumberFastField, FmkNumberField,FmkSubmitButton, FmkTextFastField, FmkUtils, FmkValidationTrigger } from "core/formik"
import { useCatchToSnackbar, useErrorHandler } from "core/hooks"
import { useGoBack } from "core/hooks/useGoBack"
import { HFlex, Spacer, VFlex } from "core/misc"
import { TodoIcon } from "core/misc/TodoIcon.com"
import { AddressDisplay } from "core/molecules/AddressDisplay"
import { BootstrapTable } from "core/molecules/BoostrapTable"
import { RawSwitch } from "core/rawFields"
import { downloadFile, openapi, rasmik } from "core/services"
import { AICIMode, Family, StandardInvoice } from "core/services/rasmik-client/entities"
import type { AICIPrestationType } from "core/services/swaggerClient"
import { useAuthContext } from "core/technic/auth"
import { customRound, DayDate, formatEuro, isHalfable, PreFmkValues, roundEuro, validateSync } from "core/utils"
import { tvaFromTTC } from "core/utils/business/business"
import { ErrorMessage,FieldArray, Formik } from "formik"
import { isNil, sumBy } from "lodash"
import max from "lodash/max"
import { DateTime } from "luxon"
import { requiredString } from "pages/family-hub/logic/validation-info/yupSchemas"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useQuery } from "react-query"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { usePrevious } from "react-use"
import * as y from "yup"

const AICIPrestationTypeOptions = {
    SCOLAIRE: "Scolaire",
    INFORMATIQUE: "Informatique",
    FRAIS: "Frais",
    INELIGIBLE: "Ineligible",
}

type PrestationType = keyof typeof AICIPrestationTypeOptions

const MAX_ECHELONNEMENT = 12
const defaultRowData: InvoiceLineData = { description: "", tauxTVA: 20, unitCount: 1, unitPriceTTCWhole: 0, prestationType: "SCOLAIRE" }

type InvoiceLineData = {
    description: string
    unitPriceTTCWhole: number //prix avant crédit d'impôt
    // unitPriceTTCWhole: number
    unitCount: number
    tauxTVA: number
    prestationType: PrestationType
}

class FormValues {
    @FmkUtils.DefField aiciMode: AICIMode
    @FmkUtils.DefField mention: string | null
    @FmkUtils.DefField comment: string | null
    @FmkUtils.DefField date: string | null
    @FmkUtils.DefField expirationDate: string | null
    @FmkUtils.DefField lines: InvoiceLineData[]
    @FmkUtils.DefField draftEcheances: StandardInvoice["draftEcheances"]
    @FmkUtils.DefField allowAltPaymentMethods: boolean
}

const fields = FmkUtils.getFields(FormValues)

const validationSchema = y.object().shape({
    [fields.aiciMode]: y.string().oneOf(["AICI", "CIDiff"], "Requis"),
    [fields.mention]: y.string().nullable().notRequired(),
    [fields.comment]: y.string().nullable().notRequired(),
    [fields.lines]: y
        .array()
        .min(1, "Au moins une ligne")
        .of(
            y.object({
                description: requiredString("Requis"),
                unitPriceTTCWhole: y.number().nullable().required("Requis").test({ test: isHalfable, message: "Non divible par 2" }),
                tauxTVA: y
                    .number()
                    .nullable()
                    .required("Requis")
                    .test({ test: (val) => [0, 10, 20].includes(val), message: "Invalide" }),
                prestationType: y
                    .string()
                    .nullable()
                    .required("Requis")
                    .test({ test: (val, ctx) => val !== "INELIGIBLE" || ctx.parent.aiciMode !== "AICI", message: "Désactiver avance immédiate" }),
            })
        )
        .min(1, "Au moins une ligne"),
    [fields.date]: y.string().nullable().required("Requis"),
    [fields.expirationDate]: y
        .string()
        .nullable()
        .required("Requis")
        .test({ test: (val, ctx) => ctx.parent.date <= val, message: `Incohérent` }),

    [fields.draftEcheances]: y
        .array()
        .nullable()
        .required("Echeances requis")
        .min(1, "Au moins une echeance")
        .of(
            y.object().shape({
                amountWhole: y.number().nullable().required("Montant requis").min(1, "Montant requis").test({ test: isHalfable, message: "Non divible par 2" }),
                monthOffset: y.number().nullable().required("Date requise").min(0, "Date requise").max(MAX_ECHELONNEMENT, `pas au dela de ${MAX_ECHELONNEMENT} mois`),
                expectedPaymentMethod: y.string().nullable().notRequired(),
            })
        )
        .test({
            test: (val, ctx) => {
                return sumBy(ctx.parent.lines, (line: InvoiceLineData) => roundEuro(line.unitCount * line.unitPriceTTCWhole)) === roundEuro(sumBy(val, (ech) => ech.amountWhole ?? 0))
            },
            message: `Montant des échéances incohérent`,
        }),
    [fields.allowAltPaymentMethods]: y.boolean().nullable().required("Requis"),
})

const familySchema = y.object({
    FamilyChief: y
        .object({
            gender: requiredString("Genre requis").nullable(),
            firstName: requiredString("Prénom requis").nullable(),
            lastName: requiredString("Nom requis").nullable(),
        })
        .nullable()
        .required("Personne de facturation à choisir"),
    FacturationAddress: y.object({}).required("Addresse de facturation requise").nullable(),
})

export interface NouveauDevisSDProps {}
export function NouveauDevisSD(props: NouveauDevisSDProps) {
    const {} = props

    const [searchParams] = useSearchParams()
    const familyId = Number(searchParams.get("family"))
    const custom = Boolean(searchParams.has("custom"))

    if (isNaN(familyId) && !custom) throw new Error("missing params")

    const auth = useAuthContext()
    const familyQuery = useQuery(
        ["family-invoicing-details", familyId],
        async () => {
            await auth.ensureValidToken()
            return await rasmik
                .readOne(Family)
                .options({
                    children: {
                        FacturationAddress: true,
                        FamilyChief: true,
                    },
                })
                .where(familyId)
                .run()
        },
        { enabled: !custom }
    )

    const [displayWhole, setDisplayWhole] = useState(true)
    const display = (wholeVal: number | null | undefined) => {
        return isNil(wholeVal) ? wholeVal : displayWhole ? wholeVal : roundEuro(wholeVal / 2)
    }
    // const display = (wholeVal: number | null | undefined, type: PrestationType) => { return type === "INELIGIBLE" ? wholeVal : isNil(wholeVal) ? wholeVal : displayWhole ? wholeVal : roundEuro(wholeVal / 2) }
    // if(payload.aiciMode === "AICI" && payload.lines.some(line=>line.prestationType === "INELIGIBLE")) throw new ApiError({status:'BAD_REQUEST', message:"AICI not allowed if some lines are 'INELIGIBLE'"})

    const [familyValid, validationMessages] = useMemo(() => {
        return validateSync(familySchema, familyQuery.data)
    }, [familyQuery.data])

    const { defaultErrorHandler } = useErrorHandler()

    const goBack = useGoBack()

    return (
        <Formik<FormValues>
            initialValues={{
                aiciMode: "CIDiff",
                date: DayDate.today().toISOString(),
                expirationDate: DayDate.today().plus({ month: 1 }).toISOString(),
                lines: [defaultRowData],
                allowAltPaymentMethods: false,
                draftEcheances: [{ amountWhole: 0, monthOffset: 0, rank: 1 }],
                mention: "",
                comment: "",
            }}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting }) => {
                try {
                    // const conf = await msgBox({
                    //     title: `Confirmation`,
                    //     msg: `Il n'est pas possible de supprimer une facture autrement qu'avec un avoir, elle apparaitra dans la comptabilité.`,
                    //     buttons: { cancel:'Annuler', continue:'Confirmer' } ,
                    // })

                    const conf = await msgBox({
                        title: `Confirmation`,
                        msg: `Il n'est pas possible de modifier un devis une fois créé.`,
                        buttons: { cancel: "Annuler", continue: "Confirmer" },
                    })

                    if (conf === "cancel") return

                    await auth.ensureValidToken()
                    const { standardInvoiceId, devisReference } = await openapi.compta.createStandardDevis({
                        aiciMode: values.aiciMode!,
                        allowAltPaymentMethods: values.allowAltPaymentMethods!,
                        devisCreatedOn: values.date!,
                        devisExpiresOn: values.expirationDate!,
                        draftEcheances: values.draftEcheances!.map((ech, idx) => ({ ...ech, rank: idx + 1 })),
                        lines: values.lines!.map((line, idx) => ({ ...line, tauxTVA: customRound(line.tauxTVA / 100, "0.01"), rank: idx + 1, unitPriceTTC: roundEuro(line.unitPriceTTCWhole / (values.aiciMode === "AICI" ? 2 : 1)) })),
                        comment: values.comment!,
                        familyId: familyId!,
                        mention: values.mention!,
                    })

                    const res = await msgBox({
                        title: `Devis créé`,
                        msg: `Nouveau devis ${devisReference} créé`,
                        buttons: { close: "Fermer", download: { label: "Télécharger", buttonProps: { variant: "contained", startIcon: <DownloadIcon /> } } },
                    })

                    if (res === "download") {
                        await downloadFile(`/pdfs/standard-devis/${standardInvoiceId}`, auth).catch((err) => {
                            defaultErrorHandler(err)
                        })
                    }

                    goBack({ fallback: "/home/ventes/devis-presta" })
                } catch (err) {
                    defaultErrorHandler(err)
                } finally {
                    setSubmitting(false)
                }
            }}
        >
            {function FormikContent({ values, setValues, setFieldValue }) {
                const totTTCWhole = roundEuro(sumBy(values.lines, (line) => roundEuro(line.unitCount * line.unitPriceTTCWhole)))
                const totTVAWhole = roundEuro(sumBy(values.lines, (line) => tvaFromTTC(line.unitCount * line.unitPriceTTCWhole, line.tauxTVA / 100)))
                const totTTCHalf = roundEuro(totTTCWhole / 2)
                // const totTTCDisplay = roundEuro(sumBy(values.lines, (line) => display(roundEuro(line.unitCount * line.unitPriceTTCWhole))))
                // const totTVADisplay = roundEuro(sumBy(values.lines, (line) => display(tvaFromTTC(line.unitCount * line.unitPriceTTCWhole, line.tauxTVA / 100))))

                const echeancesTotalWhole: number = sumBy(values.draftEcheances, (ech) => ech.amountWhole)

                const autoCalcEcheances = useCallback(() => {
                    const newEcheances: StandardInvoice["draftEcheances"] = []
                    let rest = totTTCWhole

                    for (let cpt = 1; cpt <= values.draftEcheances.length; cpt++) {
                        const oldEcheance = values.draftEcheances[cpt - 1]
                        const isLast = cpt === values.draftEcheances.length
                        const echAmount = isLast ? rest : customRound(totTTCWhole / values.draftEcheances.length, "0.02", "floor")
                        rest = rest - echAmount

                        newEcheances.push({
                            ...oldEcheance,
                            amountWhole: customRound(echAmount, "0.01", "round"),
                        })
                    }
                    //the biggest echeance at the beginning
                    const firstAmount = newEcheances[0].amountWhole
                    newEcheances[0].amountWhole = newEcheances[newEcheances.length - 1].amountWhole
                    newEcheances[newEcheances.length - 1].amountWhole = firstAmount
                    setFieldValue(fields.draftEcheances, newEcheances)
                }, [totTTCWhole, values.draftEcheances, setFieldValue])

                const previousTotTTCWhole = usePrevious(totTTCWhole)
                // fillEmptyEcheances
                useEffect(() => {
                    if (totTTCWhole > 0 && (values.draftEcheances.length === 0 || previousTotTTCWhole !== totTTCWhole)) {
                        autoCalcEcheances()
                    }
                }, [setFieldValue, values.draftEcheances.length, totTTCWhole, previousTotTTCWhole])

                useEffect(() => {
                    if (values.aiciMode === "AICI") {
                        setDisplayWhole(false)
                    } else {
                        setDisplayWhole(true)
                    }
                }, [values.aiciMode])

                return (
                    <VFlex bgcolor="#F0F0F0" minHeight="100%" py="20px">
                        <FmkValidationTrigger />
                        {/* <PreFmkValues /> */}
                        <Container>
                            <VFlex gap="15px">
                                <HFlex>
                                    <Typography variant="h2">Nouveau devis</Typography>
                                    <Spacer />
                                    <Stack>
                                        <RawSwitch rightText="Mode saisie" onClick={() => setDisplayWhole((prev) => (values.aiciMode === "AICI" ? false : !prev))} value={!displayWhole} />
                                        <Typography variant="caption">{displayWhole ? "Avant crédit d'impôt (100% du prix)" : "Après crédit d'impôt (50% du prix)"}</Typography>
                                    </Stack>
                                </HFlex>
                                {!familyValid && !custom && (
                                    <Alert severity="warning">
                                        <AlertTitle>Profil incomplet</AlertTitle>
                                        {validationMessages.map((msg, index) => (
                                            <div key={index}>{msg}</div>
                                        ))}

                                        <Box marginTop="10px">
                                            <ButtonLink className="small" variant="contained" color="primary" to={`/familles/${familyId}/profil`} target="_blank" endIcon={<OpenInNewIcon />}>
                                                Compléter
                                            </ButtonLink>
                                        </Box>
                                    </Alert>
                                )}

                                <HFlex>
                                    {familyQuery.isLoading ? <CircularProgress /> : <AddressDisplay address={familyQuery.data?.FacturationAddress} person={familyQuery.data?.FamilyChief} editLinkTo={`/familles/${familyId}/profil`} />}
                                    <Stack gap="10px">
                                        {familyQuery.isLoading ? <CircularProgress /> : <AddressDisplay address={familyQuery.data?.FacturationAddress} person={familyQuery.data?.FamilyChief} editLinkTo={`/familles/${familyId}/profil`} />}
                                        <Box display="flex" style={{ verticalAlign: "middle" }}>
                                            <div style={{ display: "flex", alignItems: "center", marginRight: "4px" }}>
                                                <TodoIcon done={!!familyQuery.data?.aiciActivationConfirmedAt} />
                                            </div>
                                            <div>{familyQuery.data.aiciActivationConfirmedAt ? `Inscription avance immédiate finalisée le ${DateTime.fromJSDate(familyQuery.data.aiciActivationConfirmedAt).toFormat("dd/MM/yyyy")}` : "Avance immédiate non activée"}</div>
                                        </Box>
                                    </Stack>
                                    <Spacer />
                                    <Stack spacing={2}>
                                        <FmkDateFastField name={fields.date} necessary validateImmediately label="Date devis" maxDate={DateTime.now()} />
                                        <FmkDateFastField name={fields.expirationDate} necessary validateImmediately label="Date expiration" />
                                    </Stack>
                                </HFlex>
                                <FmkTextFastField multiline minRows={2} fullWidth name={fields.mention} label="Mention libre" />

                                <PaddingTable style={{ borderCollapse: "collapse", width: "100%", maxWidth: "1400px", textAlign: "left" }}>
                                    <tbody>
                                        <FieldArray name={fields.lines}>
                                            {(helpers) => (
                                                <>
                                                    {(helpers.form.values as FormValues).lines.map((line, lineIdx) => {
                                                        return (
                                                            <tr key={lineIdx}>
                                                                <td style={{ verticalAlign: "top", minWidth: "350px", maxWidth: "750px" }}>
                                                                    <FmkTextFastField name={`${fields.lines}.${lineIdx}.description`} necessary validateImmediately label="Description" />
                                                                </td>
                                                                <td style={{ verticalAlign: "top", width: "175px", minWidth: "175px" }}>
                                                                    <FmkFastComboBox
                                                                        name={`${fields.lines}.${lineIdx}.prestationType`}
                                                                        necessary
                                                                        validateImmediately
                                                                        options={Object.keys(AICIPrestationTypeOptions) as (keyof typeof AICIPrestationTypeOptions)[]}
                                                                        configure={(opt) => ({ value: opt, label: AICIPrestationTypeOptions[opt] })}
                                                                    />
                                                                </td>
                                                                <td style={{ verticalAlign: "top", width: "100px", minWidth: "100px" }}>
                                                                    <FmkNumberFastField type="number" name={`${fields.lines}.${lineIdx}.unitCount`} necessary validateImmediately label="nb unit." numeric />
                                                                </td>
                                                                <td style={{ verticalAlign: "top", width: "100px", minWidth: "130px" }}>
                                                                    <FmkNumberField
                                                                        type="number"
                                                                        name={`${fields.lines}.${lineIdx}.unitPriceTTCWhole`}
                                                                        necessary
                                                                        validateImmediately
                                                                        label="tarif /unit"
                                                                        numeric
                                                                        InputProps={{ endAdornment: "€" }}
                                                                        transformInput={(val) => (isNaN(val) || isNil(val) ? val : val / (displayWhole ? 1 : 2))}
                                                                        transformOutput={(val) => (isNaN(val) || isNil(val) ? val : val * (displayWhole ? 1 : 2))}
                                                                    />
                                                                </td>
                                                                <td style={{ verticalAlign: "top", width: "100px", minWidth: "100px" }}>
                                                                    <FmkNumberFastField type="number" name={`${fields.lines}.${lineIdx}.tauxTVA`} necessary validateImmediately label="TVA" numeric InputProps={{ endAdornment: "%" }} />
                                                                </td>

                                                                <td style={{ width: "100px", minWidth: "100px" }}>
                                                                    <Typography fontWeight={600} whiteSpace="nowrap">
                                                                        {formatEuro(display((line.unitPriceTTCWhole ?? 0) * (line.unitCount ?? 0)))} TTC
                                                                    </Typography>
                                                                </td>

                                                                {/** TODO: uniquement sur la partie facture! */}

                                                                <td>
                                                                    <IconButton size="small" disabled={(helpers.form.values as FormValues).lines.length <= 1} onClick={() => helpers.remove(lineIdx)}>
                                                                        <DeleteIcon />
                                                                    </IconButton>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                    <tr>
                                                        <td colSpan={2}>
                                                            <Box>
                                                                {totTVAWhole === 0 && (
                                                                    <Typography color="error" fontWeight={600}>
                                                                        ⚠️ Aucune TVA
                                                                    </Typography>
                                                                )}
                                                                <FmkErrorMessage name={`${fields.lines}`} />
                                                            </Box>
                                                        </td>
                                                        <td colSpan={2} style={{ verticalAlign: "top" }}>
                                                            <FmkFastSwitch size="medium" name={fields.aiciMode} rightText="Avance immédiate" leftValue="CIDiff" rightValue="AICI" />
                                                        </td>
                                                        <td colSpan={1} style={{ verticalAlign: "top" }}>
                                                            <Typography fontWeight={600} whiteSpace="nowrap">
                                                                Total TVA: {formatEuro(totTVAWhole)}
                                                            </Typography>
                                                        </td>
                                                        <td colSpan={1}>
                                                            <Typography fontWeight={600} whiteSpace="nowrap" sx={values.aiciMode === "CIDiff" ? { color: "green", fontWeight: 700 } : { color: "gray", fontWeight: 500 }}>
                                                                Total TTC 100% : {formatEuro(totTTCWhole)}
                                                            </Typography>
                                                            <Typography fontWeight={600} whiteSpace="nowrap" sx={values.aiciMode === "AICI" ? { color: "green", fontWeight: 700 } : { color: "gray", fontWeight: 500 }}>
                                                                Total TTC 50% : {formatEuro(totTTCHalf)}
                                                            </Typography>
                                                        </td>
                                                        <td>
                                                            <IconButton
                                                                size="small"
                                                                onClick={() => {
                                                                    helpers.push(defaultRowData)
                                                                }}
                                                            >
                                                                <AddIcon />
                                                            </IconButton>
                                                        </td>
                                                    </tr>
                                                </>
                                            )}
                                        </FieldArray>
                                    </tbody>
                                </PaddingTable>
                                <Divider sx={{ marginY: "10px" }} />
                                <FieldArray name={fields.draftEcheances}>
                                    {(helpers) => (
                                        <PaddingTable style={{ borderCollapse: "collapse", width: "100%", maxWidth: "700px", textAlign: "left" }}>
                                            <tr>
                                                <th></th>
                                                <th colSpan={4} style={{ fontSize: "22px" }}>
                                                    <div>Echéances</div>
                                                    <div>
                                                        <FmkFastSwitch sx={{ marginTop: "20px" }} size="medium" name={fields.allowAltPaymentMethods} rightText="Autoriser paiement par chèque, virement ou cesu" necessary />
                                                    </div>
                                                </th>
                                            </tr>

                                            {values.draftEcheances
                                                .sort((a, b) => a.rank - b.rank)
                                                .map((echeance, echeanceIndex) => {
                                                    const last = echeanceIndex === values.draftEcheances.length - 1
                                                    const internalIndex = values.draftEcheances.indexOf(echeance)
                                                    return (
                                                        <>
                                                            <tr>
                                                                <td style={{ minWidth: "35px", fontSize: "18px" }}>n°{echeanceIndex + 1}</td>
                                                                <td style={{ verticalAlign: "top", width: "100%", minWidth: "100px", maxWidth: "220px" }}>
                                                                    <FmkNumberField
                                                                        name={`${fields.draftEcheances}.${internalIndex}.amountWhole`}
                                                                        type="number"
                                                                        validateImmediately
                                                                        necessary
                                                                        numeric
                                                                        InputProps={{ startAdornment: <InputAdornment position="start">€</InputAdornment> }}
                                                                        inputProps={{ step: "0.01" }}
                                                                        //On affiche le prix AICI (/2) mais on stocke en whole (x2)
                                                                        // transformInput={(val: any) => (val && (val as number) / 2) as any}
                                                                        // transformOutput={(val) => (val && Number(val) * 2) as any}
                                                                        transformInput={(val) => (isNaN(val) || isNil(val) ? val : val / (displayWhole ? 1 : 2))}
                                                                        transformOutput={(val) => (isNaN(val) || isNil(val) ? val : val * (displayWhole ? 1 : 2))}
                                                                    />
                                                                </td>
                                                                <td style={{ verticalAlign: "top", width: "100%", minWidth: "250px" }}>
                                                                    <FmkFastComboBox
                                                                        name={`${fields.draftEcheances}.${internalIndex}.monthOffset`}
                                                                        validateImmediately
                                                                        necessary
                                                                        options={Array.from({ length: 12 }, (_, i) => i)}
                                                                        configure={(opt) => ({ value: opt, label: opt === 0 ? "Dès la signature" : `${opt} mois après la signature` })}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <IconButton size="small" disabled={values.draftEcheances.length <= 1} onClick={() => helpers.remove(internalIndex)}>
                                                                        <DeleteIcon />
                                                                    </IconButton>
                                                                </td>
                                                            </tr>
                                                        </>
                                                    )
                                                })}
                                            <tr>
                                                <td colSpan={3}>
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <FmkErrorMessage name={`${fields.draftEcheances}`} />
                                                        <Spacer />
                                                        <Button className="small" variant="text" onClick={autoCalcEcheances} startIcon={<BoltIcon />}>
                                                            Calcul auto
                                                        </Button>
                                                    </div>
                                                </td>
                                                <td>
                                                    <IconButton
                                                        size="small"
                                                        disabled={values.draftEcheances.length >= MAX_ECHELONNEMENT}
                                                        onClick={() =>
                                                            helpers.push({
                                                                // rank: (max(values.draftEcheances.map(ech => ech.rank)) || 0) + 1,
                                                                monthOffset: values.draftEcheances.length === 0 ? 0 : (max(values.draftEcheances.map((ech) => ech.monthOffset)) || 0) + 1,
                                                                // expectedPaymentMethod: maxBy(values.draftEcheances, ech => ech.rank)?.expectedPaymentMethod || null,
                                                                amount: Math.max(totTTCWhole - echeancesTotalWhole, 0),
                                                            })
                                                        }
                                                    >
                                                        <AddIcon />
                                                    </IconButton>
                                                </td>
                                            </tr>
                                        </PaddingTable>
                                    )}
                                </FieldArray>
                                <FmkTextFastField multiline minRows={2} fullWidth name={fields.comment} label="Commentaire privé" />

                                {/* <ErrorMessage name={fields.lines} /> */}
                                <HFlex>
                                    <Button onClick={() => goBack({ fallback: "/home/ventes/devis-presta" })}>Annuler</Button>
                                    <Spacer />
                                    <FmkSubmitButton disabled={false}>Enregister</FmkSubmitButton>
                                </HFlex>
                            </VFlex>
                        </Container>
                    </VFlex>
                )
            }}
        </Formik>
    )
}

export function PaddingTable(props: TableProps) {
    return (
        <Table
            {...props}
            sx={{
                "&": { borderSpacing: "0px", borderRadius: "5px", fontSize: "11px" },
                "& td,th": { padding: "7px 9px" },
                "& th:first-of-type": { paddingLeft: "0px" },
                "& th:last-of-type": { paddingRight: "0px" },
                "& td:first-of-type": { paddingLeft: "0px" },
                "& td:last-of-type": { paddingRight: "0px" },
                ...props.sx,
            }}
        >
            {props.children}
        </Table>
    )
}
