import EditIcon from "@mui/icons-material/Edit"
import { Box, IconButton } from "@mui/material"
import { HFlex } from "core/misc"
import type { ReadData } from "core/services"
import type { PickData } from "core/services/rasmik-client"
import type { Address, Member } from "core/services/rasmik-client/entities"
import { Link } from "react-router-dom"

export function AddressDisplay({ person, address, editLinkTo }: { person: PickData<Member, { required: ["id", "firstName", "lastName", "gender"] }> | undefined; address: ReadData<Address> | undefined; editLinkTo?: string }) {
    return (
        <div>
            <div style={{ fontFamily: "monospace", backgroundColor: "lightgray", padding: "15px", fontWeight: 600, width: "fit-content", boxShadow: "darkgray 5px 5px 5px" }}>
                {{ M: "M.", F: "Mme" }[person?.gender]} {person?.firstName ?? "******"} {person?.lastName?.toUpperCase() ?? "******"}
                <br />
                {address ? address.fullStreet : "******************"}
                <br />
                <HFlex>
                    {address ? address.postalCode + " " + address.cityName : "***** ************"}
                    {editLinkTo && (
                        <Box>
                            <Link to={editLinkTo} target="_blank">
                                <IconButton>
                                    <EditIcon />
                                </IconButton>
                            </Link>
                        </Box>
                    )}
                </HFlex>
            </div>
        </div>
    )
}
