import { Divider, List, ListItem, ListItemSecondaryAction, ListItemText, Typography } from "@mui/material"
import { styled } from "@mui/material"
import * as colors from "@mui/material/colors"
import clsx from "clsx"
import type { ReactNode, useEffect } from "react"

export const DoubleColumnsList_old = (props: { data?: any[]; column1: any; column2: any }) => {
    const { data = [], column1, column2 } = props

    return (
        <List>
            {" "}
            {data.map((row: { [x: string]: React.ReactNode }, index: React.ReactText) => {
                return (
                    <ListItem key={index}>
                        <ListItemText>{row[column1]}</ListItemText>
                        <ListItemSecondaryAction>
                            <Typography>{row[column2]}</Typography>
                        </ListItemSecondaryAction>
                    </ListItem>
                )
            })}
        </List>
    )
}

export const DoubleColumnsList_try = ({ data = [], leftName = "left", rightName = "right" }) => {
    return (
        <List>
            {" "}
            {data.map((row, index) => {
                return (
                    <ListItem key={index}>
                        {typeof row[leftName] === "function" && row[leftName]}
                        {typeof row[leftName] !== "function" && <ListItemText>{row[leftName]}</ListItemText>}

                        <ListItemSecondaryAction>
                            {typeof row[rightName] === "function" && row[rightName]}
                            {typeof row[rightName] !== "function" && <Typography>{row[rightName]}</Typography>}
                        </ListItemSecondaryAction>
                    </ListItem>
                )
            })}
        </List>
    )
}

const TdStyled = styled("td", { shouldForwardProp: (key) => !["keyMaxWidth", "cellType", "rowSpacing"].includes(String(key)) })<{ keyMaxWidth?: string; cellType: "value" | "key" | "action"; rowSpacing?: number }>(({ theme, keyMaxWidth, cellType, rowSpacing = 3 }) => ({
    paddingRight: theme.spacing(rowSpacing),
    color: colors.grey[600],
    maxWidth: keyMaxWidth,
    ...(cellType === "key" && {
        paddingRight: theme.spacing(rowSpacing),
        color: colors.grey[600],
        maxWidth: keyMaxWidth,
    }),
    ...(cellType === "value" && {}),
}))

interface DoubleColumnsListProps {
    data?: Array<any>
    keyName?: string
    valueName?: string
    keyMaxWidth?: string
    rowSpacing?: number
}

export const DoubleColumnsList = ({ data = [], keyName = "key", valueName = "value", keyMaxWidth = "", rowSpacing = 1 }: DoubleColumnsListProps) => {
    const KeyItem = ({ content }: { content: ReactNode }) => {
        return <Typography>{content}</Typography>
    }

    const ValueItem = ({ content }: { content: ReactNode }) => {
        if (typeof content === "function") {
            const Content = content
            return Content
        } else {
            return <Typography component="div">{content}</Typography>
        }
    }

    const ActionItem = ({ action: Action }: { action: React.ElementType }) => {
        if (Action) {
            return <Action />
        } else {
            return null
        }
    }

    return (
        <table style={{ width: "100%" }}>
            <tbody>
                {data.map((row, index) => {
                    if (row.divider)
                        return (
                            <tr key={index}>
                                <td colSpan={3}>
                                    <Divider />
                                </td>
                            </tr>
                        )

                    return (
                        <tr key={index}>
                            <TdStyled cellType="key" keyMaxWidth={keyMaxWidth} rowSpacing={rowSpacing}>
                                {" "}
                                <KeyItem content={row[keyName]} />{" "}
                            </TdStyled>
                            <TdStyled cellType="value" keyMaxWidth={keyMaxWidth} rowSpacing={rowSpacing}>
                                {" "}
                                <ValueItem content={row[valueName]} />
                            </TdStyled>
                            <TdStyled cellType="action" keyMaxWidth={keyMaxWidth} rowSpacing={rowSpacing} style={{ textAlign: "right" }}>
                                {" "}
                                <ActionItem action={row.action} />
                            </TdStyled>
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
}
