import { LoginPageDialog } from "core/app/LoginPageDialog.com"
import { useDialogAwait } from "core/hooks"
import { useEzActions } from "core/store"
import jwtDecode, { InvalidTokenError } from "jwt-decode"
import React, { ReactNode, useContext, useEffect, useMemo, useRef } from "react"
import { useLocation } from "react-router-dom"
import { useInterval, useLocalStorage, useTimeout } from "react-use"

import { AUTH_STORAGE_KEY } from "./auth-constants"
import { authContext } from "./auth-context-init"

const SECURITY_DELAY_SEC = 60

export type AgentTokenPayload = {
    user: {
        id: number
        username: string
        email: string
        isAdmin: boolean
        isManager: boolean
    }
    userType: "agent"
    scopes: string[]
    iat: number
    exp: number
}

export type AuthState<P> = Partial<{
    token: string
    tokenPayload: P
    userId: number
    username: string
}>

export type AuthCtx = AuthState<AgentTokenPayload> & {
    isLoggedIn: boolean
    isTokenExpired: boolean
    isLoginDialogOpen: boolean
    hideLoginDialog(): void
    setToken(token: string): void
    eraseToken(): void
    ensureValidToken(): Promise<void>
}

export type LocalStorageAuthData = AuthState<AgentTokenPayload>

const tokenExists = (val: LocalStorageAuthData | undefined) => {
    return Boolean(val)
}
const tokenExpiresInSec = (val: LocalStorageAuthData | undefined) => {
    return val?.tokenPayload?.exp && Math.round(val?.tokenPayload?.exp - Date.now() / 1000 - SECURITY_DELAY_SEC)
}
const hasTokenExpired = (val: LocalStorageAuthData | undefined) => {
    return Boolean((tokenExpiresInSec(val) ?? 0) <= 0)
}
const isTokenValid = (val: LocalStorageAuthData | undefined) => {
    return tokenExists(val) && !hasTokenExpired(val)
}

export function AuthContextProvider({ children }: { children: ReactNode }) {
    const initialValue = {}
    const [value, setValue, remove] = useLocalStorage<LocalStorageAuthData>(AUTH_STORAGE_KEY, initialValue)
    const location = useLocation()
    const setEzAuth = useEzActions((s) => s.setAuth)
    const valueRef = useRef(value)
    valueRef.current = value

    const loginDialog = useDialogAwait<void, void>()

    //check for expired token every 10s
    useEffect(() => {
        const timer = setInterval(() => {
            //if there's no token, login page is displayed by react router (PrivateRoute)
            if (location.pathname === "/login" || !tokenExists(value) || loginDialog.isOpen) return

            //If there's a token but it is not valid, login page is displayed by LoginPageDialog
            if (hasTokenExpired(value)) {
                loginDialog.open()
            }
            // console.log(`token expires in ${tokenExpiresInSec(value)}s`)
        }, 10 * 1000)

        return () => {
            clearInterval(timer)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, loginDialog.isOpen, location.pathname])

    const contextValue = useMemo(
        () => ({
            ...value,
            get isLoggedIn() {
                return !!valueRef.current?.token
            },
            get isTokenExpired() {
                return hasTokenExpired(value)
            },
            isLoginDialogOpen: loginDialog.isOpen,
            hideLoginDialog: loginDialog.close as () => void,
            /** decode token and store in localstorage + state */
            setToken(token: string) {
                if (!token) throw new Error("Can't store empty token")

                try {
                    const decodedToken = jwtDecode<AgentTokenPayload>(token)

                    const newValue = {
                        token: token,
                        tokenPayload: decodedToken,
                        userId: decodedToken.user.id,
                        username: decodedToken.user.username,
                    }
                    setValue(newValue)

                    valueRef.current = newValue
                } catch (err) {
                    if (err instanceof InvalidTokenError) {
                        console.log("Invalid jwt token")
                        throw err
                    } else {
                        throw err
                    }
                }
            },
            /** remove token from state and localStorage */
            eraseToken() {
                remove()
            },
            async ensureValidToken() {
                if (isTokenValid(valueRef.current)) {
                    // console.log("token is valid",valueRef.current)
                    return
                } else {
                    console.log("token is not valid", valueRef.current)
                    return loginDialog.open(undefined) as any as Promise<void>
                }
            },
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [value, loginDialog.isOpen]
    )

    useEffect(() => {
        setEzAuth(contextValue)
    }, [contextValue, setEzAuth])

    return (
        <authContext.Provider value={contextValue}>
            <LoginPageDialog />
            {children}
        </authContext.Provider>
    )
}
