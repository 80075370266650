import MaterialTable, { Column as StdColumn, MaterialTableProps as StdMaterialTableProps, MTableCell,MTableEditField } from "@material-table/core"
import { FormControl, FormHelperText, MenuItem, Select } from "@mui/material"
import dottie from "dottie"
import React, { useEffect } from "react"
import { useState } from "react"

type MTableFieldProps = {
    /** The raw value (may be an object or array) */
    value: any
    rowData: any
    columnDef: MaterialTableCustomColumn

    onChange: (selectedKey: any) => void
    onRowDataChange: (...params: any[]) => void

    error: boolean
    locale?: string
    helperText: string
}

type CustomLookup<LookupRowData extends any = any> = {
    lookupData?: LookupRowData[]
    transformExport?: (selectedLookupRow: LookupRowData) => any
    getOptionLabel: (lookupRow: LookupRowData) => string
    getOptionSelected: (lookupRow: LookupRowData, value: any) => boolean
    none?: boolean
    noneLabel?: string
}

export interface MaterialTableCustomColumn<RowData extends object = Record<string, any>> extends StdColumn<RowData> {
    customLookup?: CustomLookup<RowData>
    lookup?: Record<string, string | number>
    lookupNone?: boolean
    lookupNoneLabel?: string
}

export interface MaterialTableCustomProps<RowData extends object> extends Omit<StdMaterialTableProps<RowData>, "columns"> {
    columns: MaterialTableCustomColumn<RowData>[]
}

/**
 * @material-table/core exportFunc have only partial cols definition and data is only an array of rendered data, not the original data.
 * The purpose of this component is to pass all columns and data to the ExportClipboard function
 */

/**
 * Other purpose is to have customLookup column prop (to have control on what's rendered, to work on array of objects instead of dict) but it implies overriding the Cell, Editcell and Filter component and maybe others) - not finished
 */

export function MaterialTableCustom<RowData extends object = Record<string, any>>(props: MaterialTableCustomProps<RowData>) {
    // const [columns] = useState(props.columns)
    const columns = props.columns

    // useEffect(()=>setColumns(props.columns),[!!columns])
    if (props.options?.exportMenu)
        props.options.exportMenu = props.options?.exportMenu?.map((exprtDef) => ({
            label: exprtDef.label,
            exportFunc: () => exprtDef.exportFunc(props.columns, props.data as RowData[], undefined as any),
        }))

    return (
        <MaterialTable
            {...props}
            // columns={columns}

            components={{
                EditField: React.forwardRef((fieldProps: MTableFieldProps, ref) => {
                    fieldProps.columnDef.customLookup && setCustomLookupDefaults(fieldProps.columnDef.customLookup)

                    if (fieldProps.columnDef.lookup) {
                        return <MTableEditField_Lookup {...fieldProps} forwardedRef={ref} />
                    } else if (fieldProps.columnDef.customLookup) {
                        return <MTableEditField_CustomLookup {...fieldProps} forwardedRef={ref} />
                    } else {
                        return <MTableEditField {...fieldProps} ref={ref} /*{...{ ...fieldProps, value: fieldProps.value || '' }}*/ />
                    }
                }),

                Cell: React.forwardRef((fieldProps: MTableFieldProps, ref) => {
                    fieldProps.columnDef.customLookup && setCustomLookupDefaults(fieldProps.columnDef.customLookup)

                    if (fieldProps.columnDef.customLookup && fieldProps.rowData) {
                        return <MTableCell_CustomLookup {...fieldProps} />
                    } else {
                        return <MTableCell {...fieldProps} ref={ref} /*{...{ ...fieldProps, value: fieldProps.value || '' }}*/ />
                    }
                }),
            }}
        />
    )
}

function MTableEditField_Lookup({ forwardedRef, ...props }: MTableFieldProps & { forwardedRef: any }) {
    return (
        <FormControl ref={forwardedRef} error={Boolean(props.error)}>
            <Select
                {...props}
                value={props.value === undefined ? "" : props.value}
                onChange={(event) => props.onChange(event.target.value)}
                style={{
                    fontSize: 12,
                }}
                SelectDisplayProps={{}}
            >
                {props.columnDef.lookupNone && (
                    <MenuItem value={null}>
                        <em>{props.columnDef.lookupNoneLabel || "Vide"}</em>
                    </MenuItem>
                )}
                {Object.keys(props.columnDef.lookup).map((key) => (
                    <MenuItem key={key} value={key}>
                        {props.columnDef.lookup[key]}
                    </MenuItem>
                ))}
            </Select>
            {Boolean(props.helperText) && <FormHelperText>{props.helperText}</FormHelperText>}
        </FormControl>
    )
}

function MTableEditField_CustomLookup(fieldProps: MTableFieldProps & { forwardedRef: any }) {
    const columnDef: MaterialTableCustomColumn = fieldProps.columnDef
    const lookup = columnDef.customLookup

    const fieldValue = fieldProps.value //object or string or number ...
    const selectedIndex = lookup.lookupData!.findIndex((option) => {
        return lookup.getOptionSelected(option, fieldValue)
    })

    return (
        <FormControl error={Boolean(fieldProps.error)} ref={fieldProps.forwardedRef}>
            <Select
                {...fieldProps}
                value={selectedIndex}
                onChange={(event) => {
                    const newIndex = event.target.value as number

                    if (newIndex === -1 && lookup.none) {
                        fieldProps.onChange(null)
                        return
                    }

                    const newOption = lookup.lookupData[newIndex]
                    fieldProps.onChange(lookup.transformExport(newOption))
                }}
                style={{
                    fontSize: 12,
                }}
                SelectDisplayProps={{ "aria-label": fieldProps.columnDef.title as string }}
            >
                {lookup.none && (
                    <MenuItem value={-1}>
                        <em>{lookup.noneLabel || "Vide"}</em>
                    </MenuItem>
                )}
                {lookup.lookupData.map((option, index) => (
                    <MenuItem key={index} value={index}>
                        {lookup.getOptionLabel(option)}
                    </MenuItem>
                ))}
            </Select>
            {Boolean(fieldProps.helperText) && <FormHelperText>{fieldProps.helperText}</FormHelperText>}
        </FormControl>
    )

    //   return <Autocomplete

    //   value={fieldValue}
    //   options={lookup.lookupData}
    //   getOptionLabel={lookupRow=>lookup.getOptionLabel(lookupRow)}
    //   getOptionSelected={lookup.getOptionSelected}

    //   onChange={(e,selectedKey)=>{fieldProps.onChange(()=>lookup.transformExport(selectedKey))}} //on devrait aussi provider la row
    //   renderInput={(params) => <TextField {...params} value={} />}
    //   />
}

function MTableCell_CustomLookup(fieldProps: MTableFieldProps) {
    const columnDef: MaterialTableCustomColumn = fieldProps.columnDef
    const lookup = columnDef.customLookup

    const fieldValue = dottie.get(fieldProps.rowData, columnDef.field as string)

    const optionRow = lookup.lookupData!.find((lookupRow) => lookup.getOptionSelected(lookupRow, fieldValue || {}))

    let labelValue: string

    if (!optionRow && fieldValue && lookup.lookupData.length) {
        labelValue = "Non trouvé !"
        console.error("MaterialTableCustom : option for value ", fieldValue, "not found in column " + columnDef.field)
    } else {
        labelValue = optionRow ? lookup.getOptionLabel(optionRow) : ""
    }

    return <MTableCell {...fieldProps} value={labelValue} />
}

function setCustomLookupDefaults(customLookup: CustomLookup) {
    if (!customLookup) return

    if (!customLookup.lookupData) customLookup.lookupData = []
    // if(!lookup.getOptionLabel)  lookup.getOptionLabel = (lookupRow)=>lookupRow.label
    // if(!lookup.getOptionKey) lookup.getOptionKey = (lookupRow)=>lookupRow.id
    if (!customLookup.transformExport) customLookup.transformExport = (selectedOptionRow) => selectedOptionRow
}
// return (
//     <MaterialTable
//         {...props}
//         columns={columns}

//         components={{
//             EditField: (fieldProps:MTableFieldProps) => {
//                 const columnDef: MaterialTableCustomColumn<RowData> = fieldProps.columnDef
//                 const lookup = columnDef.lookup
//                 alert(fieldProps)
//                 if (lookup) {

//                   const fieldValue = fieldProps.value //object or string or number ...
//                   const rowData =  fieldProps.rowData

//                   console.info(fieldProps)
//                   return <Autocomplete

//                   value={lookup.getValueKey(fieldValue,rowData, columnDef)}
//                   options={lookup.lookupData}
//                   getOptionLabel={lookupRow=>lookup.getOptionLabel(lookupRow)}
//                   getOptionSelected={(option,selectedKey)=>lookup.getOptionKey(option) === selectedKey}

//                   onChange={(e,selectedKey)=>{fieldProps.onChange(()=>lookup.transformExport(selectedKey))}} //on devrait aussi provider la row
//                   renderInput={(params) => <TextField {...params} />}
//                   />
//                 } else {
//                   return <MTableEditField {...fieldProps}  /*{...{ ...fieldProps, value: fieldProps.value || '' }}*/ />
//                 }
//               },
//         }}
//     />
// )
