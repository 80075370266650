import { Tabs as TabsUnstyled, TabsProps as TabsUnstyledProps } from "@mui/base"
import { TabsProps } from "@mui/material"
import { Box, styled } from "@mui/material"

export const SubTabsContent = styled(TabsUnstyled)({
    display: "flex",
    flexWrap: "nowrap",
    // overflowX:"auto",
    // overflowY:'hidden',
    borderBottom: "1px solid #c6c9cc",
    padding: "0px 15px",
    "::before ::after": { boxSizing: "border-box" },
})

export function SubTabs(props: TabsUnstyledProps) {
    return (
        <Box sx={{ overflowX: "auto", scrollBehavior: "smooth", paddingBottom: "5px" }}>
            <SubTabsContent {...props} />
        </Box>
    )
}
