import { Box, BoxProps } from "@mui/material"
import type { ReactNode } from "react"
import React from "react"

export type NotionChipColor = "yellow" | "light-gray" | "brown" | "blue" | "red" | "gray" | "green" | "pink" | "purple" | "orange"

export const NotionChip = React.forwardRef(function NotionChip(props: { color: NotionChipColor; children: ReactNode } & Omit<BoxProps, "color">, ref) {
    const { color, children, ...rest } = props
    let bckgrdColor: string
    let txtColor: string

    switch (color) {
        case "yellow":
            bckgrdColor = "#FDECC8"
            txtColor = "#695540"
            break
        case "light-gray":
            bckgrdColor = "#F1F0EF"
            txtColor = "#494743"
            break
        case "brown":
            bckgrdColor = "#EEE0DA"
            txtColor = "#442A1E"
            break
        case "blue":
            bckgrdColor = "#D3E5EF"
            txtColor = "#284256"
            break
        case "red":
            bckgrdColor = "#FFE2DD"
            txtColor = "#6D2B29"
            break
        case "gray":
            bckgrdColor = "#E3E2E0"
            txtColor = "#32302C"
            break
        case "green":
            bckgrdColor = "#DBEDDB"
            txtColor = "#1C3829"
            break
        case "pink":
            bckgrdColor = "#F5E0E9"
            txtColor = "#4C2337"
            break
        case "purple":
            bckgrdColor = "#E8DEEE"
            txtColor = "#412454"
            break
        case "orange":
            bckgrdColor = "#FADEC9"
            txtColor = "#49290E"
            break
        default:
            bckgrdColor = "#F1F0EF"
            txtColor = "#494743"
            break
    }

    return (
        <Box
            ref={ref}
            {...rest}
            sx={{
                borderRadius: "3px",
                backgroundColor: bckgrdColor,
                fontSize: "14px",
                fontWeight: 500,
                color: txtColor,
                padding: "2px 3px",
                textAlign: "center",
                display: "flex",
                lineHeight: "120%",
                justifyContent: "center",
                whiteSpace: "nowrap",
                width: "fit-content",
                height: "fit-content",
                ...rest.sx,
            }}
        >
            {children}
        </Box>
    )
})

//Version de Pierre
// -sans passage de toutes les box props
// -sans switch
// export function NotionChip(props: { color: NotionChipColor; sx?: BoxProps["sx"]; children: ReactNode }) {
//     const { color, sx, children } = props
//     let bckgrdColor: string
//     let txtColor: string
//     if (color === "yellow") {
//         bckgrdColor = "#FDECC8"
//         txtColor = "#695540"
//     }
//     if (color === "light-gray") {
//         bckgrdColor = "#F1F0EF"
//         txtColor = "#494743"
//     }
//     if (color === "brown") {
//         bckgrdColor = "#EEE0DA"
//         txtColor = "#442A1E"
//     }
//     if (color === "blue") {
//         bckgrdColor = "#D3E5EF"
//         txtColor = "#284256"
//     }
//     if (color === "red") {
//         bckgrdColor = "#FFE2DD"
//         txtColor = "#6D2B29"
//     }
//     if (color === "gray") {
//         bckgrdColor = "#E3E2E0"
//         txtColor = "#32302C"
//     }
//     if (color === "green") {
//         bckgrdColor = "#DBEDDB"
//         txtColor = "#1C3829"
//     }
//     if (color === "pink") {
//         bckgrdColor = "#F5E0E9"
//         txtColor = "#4C2337"
//     }
//     if (color === "purple") {
//         bckgrdColor = "#E8DEEE"
//         txtColor = "#412454"
//     }
//     if (color === "orange") {
//         bckgrdColor = "#FADEC9"
//         txtColor = "#49290E"
//     }
//     return <Box sx={{ borderRadius: "3px", backgroundColor: bckgrdColor, fontSize: "14px", fontWeight: 500, color: txtColor, padding: "2px 3px", textAlign: "center", margin: "6px", display: "flex", lineHeight: "120%", justifyContent: "center", whiteSpace: "nowrap", ...sx }}>{children}</Box>
// }
