import { DateTime, DurationLike } from "luxon"

//TODO: test

// const defaultTimeZone = 'europe/paris'
export class DayDate {
    static isDayDateString(val: any) {
        return !!val && typeof val === "string" && /^\d{4}-[0-1]\d-[0123]\d$/.test(val)
    }
    static fromISOString(dayDateString: string /*, sourceZone:string = defaultTimeZone*/) {
        if (!dayDateString) return null
        if (!DayDate.isDayDateString(dayDateString)) throw new Error(`invalid dayDate string '${dayDateString}'`)
        return new DayDate(dayDateString /*,sourceZone*/)
    }

    static fromJSDate(date: Date /*,targetZone:string = defaultTimeZone*/) {
        if (!date) return null
        const dayDateString = DateTime.fromJSDate(date).toFormat("yyyy-MM-dd" /*,{timeZone:targetZone}*/)
        return DayDate.fromISOString(dayDateString /*,targetZone*/)
    }

    static fromLuxonDateTime(datetime: DateTime /*,targetZone:string = defaultTimeZone*/) {
        if (!datetime) return null
        const dayDateString = datetime.toFormat("yyyy-MM-dd" /*,{timeZone:targetZone}*/)
        return DayDate.fromISOString(dayDateString /*,targetZone*/)
    }

    static today(/*targetZone:string = defaultTimeZone*/) {
        return DayDate.fromJSDate(new Date() /*,targetZone*/)
    }

    constructor(
        private dayDateString: string // private timeZone:string = defaultTimeZone
    ) {}

    toLuxonDateTime() {
        return DateTime.fromFormat(this.dayDateString, "yyyy-MM-dd" /*,{zone:this.timeZone}*/)
    }

    toJsDate() {
        return this.toLuxonDateTime().toJSDate() //convertit à 0h00 de la zone en question (donc a 23h la veille à à GMT-0)
    }

    toFormat(format: string) {
        return this.toLuxonDateTime().toFormat(format /*,{timeZone:this.timeZone}*/)
    }

    toISOString() {
        return this.dayDateString
    }

    // /** Change the output timezone for string to jsdate convertion */
    // changeTimeZone(newTimeZone:string){
    //     this.timeZone = newTimeZone
    //     return this
    // }

    startOfTrimester() {
        const lxn = this.toLuxonDateTime()
        return DayDate.fromLuxonDateTime(lxn.startOf("quarter"))
    }
    endOfTrimester() {
        const lxn = this.toLuxonDateTime()
        return DayDate.fromLuxonDateTime(lxn.endOf("quarter"))
    }

    plus(duration: DurationLike) {
        const lxn = this.toLuxonDateTime()
        return DayDate.fromLuxonDateTime(lxn.plus(duration))
    }

    minus(duration: DurationLike) {
        const lxn = this.toLuxonDateTime()
        return DayDate.fromLuxonDateTime(lxn.minus(duration))
    }

    endOfSchoolYear() {
        const month = this.getMonth()
        if (month <= 7) {
            return `${this.getYear().toString().padStart(4, "0")}-07-31`
        } else {
            return `${(this.getYear() + 1).toString().padStart(4, "0")}-07-31`
        }
    }
    startOfSchoolYear() {
        const month = this.getMonth()
        if (month >= 8) {
            return `${this.getYear().toString().padStart(4, "0")}-08-01`
        } else {
            return `${(this.getYear() - 1).toString().padStart(4, "0")}-08-01`
        }
    }

    startOfMonth() {
        const lxn = this.toLuxonDateTime()
        return DayDate.fromLuxonDateTime(lxn.startOf("month"))
    }

    endOfMonth() {
        const lxn = this.toLuxonDateTime()
        return DayDate.fromLuxonDateTime(lxn.endOf("month"))
    }

    getDay() {
        return parseInt(this.dayDateString.slice(8, 10), 10)
    }

    getMonth() {
        return parseInt(this.dayDateString.slice(5, 7), 10)
    }

    getYear() {
        return parseInt(this.dayDateString.slice(0, 4), 10)
    }

    gte(dateStr: string): boolean {
        const comparaison = this.dayDateString.localeCompare(dateStr)
        return comparaison === 1 || comparaison === 0
    }

    gt(dateStr: string): boolean {
        return this.dayDateString.localeCompare(dateStr) === 1
    }

    lte(dateStr: string): boolean {
        const comparaison = this.dayDateString.localeCompare(dateStr)
        return comparaison === -1 || comparaison === 0
    }

    lt(dateStr: string): boolean {
        return this.dayDateString.localeCompare(dateStr) === -1
    }
}
