import { DependencyList, useCallback } from "react"

import { BoundaryErrorHandlerOptions, SnackbarErrorHandlerOptions, useErrorHandler } from "./useErrorHandler"

type Callback = (...args: any[]) => any

/**
 * Creates a hook that will forward any errors to the given error handler
 */
export function creatUseCatchHook(handler: (err: any) => void) {
    return function useCatch<C extends Callback>(callback: C, deps?: DependencyList): C {
        const wrappedCallback = (...args: any[]) => {
            try {
                const res = callback(...args)
                if (res instanceof Promise) {
                    res.catch((err) => {
                        handler(err)
                    })
                }
            } catch (err) {
                handler(err)
            }
        }

        if (deps) {
            //eslint-disable-next-line
            return useCallback(wrappedCallback, deps) as C
        } else {
            return wrappedCallback as C
        }
    }
}

/**
 * Spécifique au projet
 * Catch les erreurs et les forward au defaultErrorHandler en mode "boundary"
 */
export function useCatchToBoundary<C extends Callback>(callback: C, options?: Omit<BoundaryErrorHandlerOptions, "mode">, deps?: DependencyList): C {
    const { defaultErrorHandler } = useErrorHandler()

    const handler = (err: unknown) => {
        defaultErrorHandler(err, { mode: "boundary", ...options })
    }

    const catchHook = creatUseCatchHook(handler)
    return catchHook(callback, deps)
}

/**
 * Sprécifique au projet
 * Catch les erreurs et les forward au defaultErrorHandler en mode "snackbar"
 */
export function useCatchToSnackbar<C extends Callback>(callback: C, options?: Omit<SnackbarErrorHandlerOptions, "mode">, deps?: DependencyList): C {
    const { defaultErrorHandler } = useErrorHandler()

    const handler = (err: unknown) => {
        defaultErrorHandler(err, { mode: "snackbar", ...options })
    }

    const catchHook = creatUseCatchHook(handler)
    return catchHook(callback, deps)
}
