import React, { ReactElement } from "react"
import { Navigate, Route, RouteObject, Routes, useRoutes } from "react-router-dom"

export function useMatchSubRoute<T extends string>(paths: T[]): T {
    const matchedRoute = useRoutes((paths as string[]).map((path) => ({ path: /* encodeURI */ path, element: /* encodeURI */ path })))
    return matchedRoute?.props.children ? decodeURI(matchedRoute?.props.children) : matchedRoute?.props.children
}

function useTabRoutesImpl(baseOrDef: any, defOrEmpty: any) {
    let routesDef: (Omit<RouteObject, "children"> & { tabLabel?: string; navigateTo?: string })[]

    if (defOrEmpty) {
        const base = baseOrDef
        routesDef = defOrEmpty
        routesDef.forEach((def) => {
            def.path = [base, def.path].join("/")
        })
    } else {
        routesDef = baseOrDef
    }

    const encodedRoutesDef = routesDef.map((def) => ({ ...def, path: def.path /* path: encodeURI(def.path!) */ }))

    const tabs = encodedRoutesDef.filter((route) => !!route.tabLabel).map((route) => ({ path: route.path, label: route.tabLabel, to: route.path.endsWith("/*") ? route.path.slice(0, -2) : route.path }))

    const pathStringElement = useRoutes(encodedRoutesDef.map((route) => ({ element: route.path, path: route.path, caseSensitive: route.caseSensitive, index: route.index })))
    const matchedPath: string | undefined = pathStringElement?.props.children

    const matchedDef = encodedRoutesDef.find((route) => route.path === matchedPath)
    const unmatched = !matchedDef
    const noMatchedTabs = !tabs.some((tab) => tab.path === matchedPath)

    let matchedEl = matchedDef?.element

    if (matchedDef?.navigateTo) {
        matchedEl = React.createElement(Navigate, { to: encodeURI(matchedDef?.navigateTo), replace: true })
    }

    const routesElements: ReactElement[] = []

    for (const def of encodedRoutesDef) {
        const renderEl = def.navigateTo ? React.createElement(Navigate, { to: encodeURI(def.navigateTo), replace: true }) : def.element
        routesElements.push(<Route path={def.path} element={renderEl} key={def.path} />)
    }

    const routesEl = <Routes>{routesElements}</Routes>

    return { matchedPath, tabs, noMatchedTabs, routesEl, matchedEl }
}

function useTabRoutes(routesDef: (Omit<RouteObject, "children"> & { tabLabel?: string; navigateTo?: string })[]): ReturnType<typeof useTabRoutesImpl>
function useTabRoutes(base: string, routesDef: (Omit<RouteObject, "children"> & { tabLabel?: string; navigateTo?: string })[]): ReturnType<typeof useTabRoutesImpl>
function useTabRoutes(baseOrDef: any, defOrEmpty?: any) {
    return useTabRoutesImpl(baseOrDef, defOrEmpty)
}
export { useTabRoutes }
